* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", sans-serif;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}

button {
    color: #fff;
    cursor: pointer;
    letter-spacing: 0.5px;
}

ul {
    list-style-type: none;
}

img {
    width: 100%;
}