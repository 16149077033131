.wrapper {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/images/bg_ebook2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    position: relative;
    padding: 30px;
    display: grid;
    gap: 40px;
    color: #fff;

    @media (min-width: 768px) {
        padding: 40px 70px;
    }

    @media (min-width: 1024px) {
        grid-template-columns: 1.4fr 1fr;
        gap: 35px;
    }

    @media (min-width: 1440px) {
        padding: 40px 200px;
        gap: 45px;
    }

    @media (min-width: 1900px) {
        grid-template-columns: 1.6fr 1fr;
        padding: 40px 350px;
        gap: 60px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    & img {
        width: 300px;
        margin: 20px 0;
    }

    & strong,
    &__highlight span {
        color: #56bc90;
    }

    &__highlight h2 {
        color: #d2d2d2d2;
        margin-top: 10px;
        font-size: 26px;
        font-weight: normal;
        text-transform: uppercase;
        line-height: 40px;
        letter-spacing: 0.5px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    &__text h3 {
        font-size: 20px;
        margin-bottom: 8px;
        letter-spacing: 0.4px;
    }

    &__text p {
        line-height: 25px;
    }
}

.contato {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    align-self: center;

    & img {
        width: 300px;
    }

    &__descricao {
        text-align: center;
        color: #eeeeee;
        display: flex;
        flex-direction: column;
        gap: 10px;

        & p {
            font-size: 28px;
        }
    }

    &__highlight {
        color: #56bc90;
    }
}

.form {
    display: grid;
    gap: 15px;
    width: 100%;

    @media (min-width: 1024px) {
        gap: 20px;
    }

    & label {
        display: block;
        margin-bottom: 8px;
        color: #efefef;
    }

    & input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: none;
        background-color: #fafafa;

        &.invalid {
            border: 2px solid #ef6363;
            color: #ef6363;
        }
    }

    & button {
        background-color: #56bc90;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 50px;
        height: 50px;
        border: none;
        border-radius: 25px;
        font-size: 18px;
        margin: 15px 0;
    }

    &__invalid {
        color: #ff2b2b;
        text-align: center;
        background-color: #ffffffcf;
        font-weight: bold;
        padding: 10px 5px;
        border-radius: 5px;
        font-size: 14px;
        letter-spacing: 0.3px;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.7);

    &_centered {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 900;
    }
}

.card {
    padding: 15px 25px;
    background-color: #fff;
    box-shadow: 0 0 3px #696969;
    border-radius: 4px;
    height: fit-content;
    position: relative;

    @media (min-width: 1400px) {
        padding: 20px;
    }
}

.modal {
    width: 260px;
    display: grid;
    gap: 15px;
    text-align: center;

    h4 {
        font-size: 2rem;
        color: #454545;
        margin-top: 40px;
    }

    p {
        color: #565656;
        letter-spacing: 0.5px;
        font-size: 1.1rem;
    }

    img {
        width: 120px;
        justify-self: center;
        position: absolute;
        top: -75px;

        &.error {
            border-color: #f72a2a;
        }

        &.success {
            border-color: #1a961a;
        }
    }

    button {
        border: none;
        background-color: transparent;
        font-size: 1.2rem;
        cursor: pointer;
        padding: 10px 0;
        border-radius: 5px;
        transition: 0.2s ease-in-out;

        &.error {
            color: #fff;
            background-color: #f72a2a;

            &:hover {
                background-color: darken($color: #f72a2a, $amount: 25);
            }
        }

        &.success {
            background-color: #1a961a;
            color: #fff;

            &:hover {
                background-color: darken($color: #1a961a, $amount: 25);
            }
        }
    }

    @media (min-width: 768px) {
        width: 350px;
    }
}
